<script setup lang="ts">
import type { Form } from '~/types';
import type { FormError } from '@nuxt/ui/dist/runtime/types';
//import { ProposalsBlocksPricing } from '#build/components';

const config = useRuntimeConfig();
const RECAPTCHA_TOKEN_URL = config.public.recaptchaTokenURL;
const RECAPTCHA_SITE_KEY = config.public.recaptchaSiteKey;

if (!RECAPTCHA_TOKEN_URL) {
  console.log('RECAPTCHA_TOKEN_URL', RECAPTCHA_TOKEN_URL);
  throw new Error('RECAPTCHA_TOKEN_URL is not set in environment variables.');
}

if (!RECAPTCHA_SITE_KEY) {
  console.log('RECAPTCHA_SITE_KEY', RECAPTCHA_SITE_KEY);
  throw new Error('RECAPTCHA_SITE_KEY is not set in environment variables.');
}

const props = defineProps<{
  form: Form;
}>();

const emit = defineEmits(['submit', 'update:modelValue']);

// Get query params to allow prefilling of form fields
const { query } = useRoute();
const formData = reactive({ ...query });
const loading = ref(false);
const error: any = ref(null);
const success = ref(false);

const validate = (state: any): FormError[] => {
  const errors = [];

  //test formData.email for a valid email address
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(state.email)) errors.push({ path: 'email', message: 'Invalid email' });
  if (!state.email) errors.push({ path: 'email', message: 'Required' });
  if (!state.first_name) errors.push({ path: 'first name', message: 'Required' });
  if (!state.last_name) errors.push({ path: 'last name', message: 'Required' });
  if (!state.phone) errors.push({ path: 'phone', message: 'Required' });
  if (!state.message) errors.push({ path: 'message', message: 'Required' });
  if (!state.consent) errors.push({ path: 'consent', message: 'Required' });

  return errors;
};

async function submitForm() {
  loading.value = true;

  if (validate(formData).length) {
    //create a string of all the error Paths with their Messages and assign to the error const
    error.value = validate(formData)
      .map((error) => `${error.path}: ${error.message}`)
      .join(', ');

    return false;
  }

  //if we got here error.value should be false
  error.value = false;

  //do api validation from recaptcha here make server call
  grecaptcha.enterprise.ready(async function () {
    const action = 'submit'; //TODO: Modify this to pull the dynamicly from Consus.
    const token = await grecaptcha.enterprise.execute(config.public.recaptchaSiteKey, { action });

    // Add your logic to submit to your backend server here.
    console.log('\nGot Token, calling /api/recaptcha: ', token);

    const score = await $fetch('/api/recaptcha', {
      params: {
        token,
        action,
      },
    });

    console.info('\nscore', score);

    if (score > 0.5) {
      console.log('score is good');

      try {
        await useDirectus(
          createItem('site_inbox', {
            data: formData,
            form: props.form.id,
          }),
        );

        success.value = true;

        if (props.form.on_success === 'redirect') {
          return navigateTo(props.form.redirect_url);
        }
      } catch (err: any) {
        error.value = err;
      } finally {
        loading.value = false;
      }
    } else {
      console.log('score is bad');
    }
  });
}

watch(
  formData,
  () => {
    emit('update:modelValue', formData);
    validate(formData);
  },
  { deep: true },
);
</script>
<template>
  <div v-auto-animate>
    <div class="mb-4">
      <VAlert v-if="error" type="error">Oops! {{ error }}</VAlert>
      <VAlert v-if="form.on_success === 'message' && success" type="success">
        <div class="items-center" v-html="form.success_message ?? 'Success! Your form has been submitted.'" />
        <div class="items-center">
          <UButton
            to="/"
            class="text-white dark:text-white bg-totem-pole ml-3 items-center"
            variant="solid"
            size="md"
            label="Back to Home"
          />
        </div>
        <!--  -->
      </VAlert>
    </div>

    <div>
      <FormCustom
        v-if="!success"
        :schema="props.form.schema"
        :form-key="props.form.key"
        :state="formData"
        :validate="validate"
        class="grid gap-6 md:grid-cols-6"
        :on-submit="submitForm"
      />
      <div id="recaptchadiv"></div>
    </div>
  </div>
</template>
